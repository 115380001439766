
:root {
  --nl-main-bg: rgb(227, 227, 227);
  --nl-header-bg: rgb(215, 215, 215);
  --nl-green: rgb(109, 166, 126);
  --nl-blue: rgb(0, 121, 165);
  --nl-text: rgb(70, 70, 70);
  --nl-performer-byline: rgb(215, 110, 94);
  --nl-off-white: #F9F9F9;
  --bs-body-font-size: 1.2rem;
  --nl-font-avenir: Avenir, Montserrat, Arial, sans-serif;
  --nl-gutter: 10px;
}

// Basics
body {

  div#root {
    margin-bottom: 5rem;
  }

  font-family: var(--nl-font-avenir);
  font-size: 17px;

  //background-color: #F1F1F1;
  background-color: var(--nl-main-bg);
  color: var(--nl-text);

  h1, h2, h3, h4, h5, h6 {
    font-family: var(--nl-font-avenir);
    color: #111111;
  }

  .text-blue {
    color: rgb(0, 121, 165);
  }

  .box-border-green {
    border-color: var(--nl-green);
  }

  .text-green {
    color: var(--nl-green);
  }

  a, .nav-link {
    color: var(--nl-text);
    text-decoration: underline;
  }

  .page-callout {
    color: var(--nl-blue);
    font-size: 20px;
    text-align: center;
    padding: 25px 15%;
    @media (max-width: 768px) {
      font-size: 16px;
      padding: 20px 35px;
    }
  }

}

// Container
.container-app, {
  max-width: 767px !important;
  //margin-bottom: 2rem;
  padding: 0; // var(--nl-gutter);
  nav#nav-faves > div.container {
    max-width: 767px !important;
  }
}

.nl-gutter {
  padding-left: var(--nl-gutter);
  padding-right: var(--nl-gutter);
}

// My Favourites button
#nav-faves {
  //background-color: var(--nl-main-bg);
  font-size: 1.4rem;
  margin-bottom: 10px;
  margin-right: 10px;
  a.nav-link {
    text-decoration: none;
    color: var(--nl-off-white);
    background-color: var(--nl-green);
    padding: 6px 12px;
    border-color: var(--nl-green);
    border-radius: 16px;
    > svg {
      margin-right: 10px;
      margin-top: -5px;
    }
  }
}
// Favourites and PWA install button
a.nav-link.pwa-install {
  // Only show on home screen
  display: none;
}
body.nl-page-home {
  //font-size: 1.5rem;
  margin-bottom: 20px;
  margin-right: 10px;
  #nav-faves {
    a.nav-link {
      &.fav-button {
        &::after {
          content: 'Favourites';
          margin-left: 10px;
        }
      }
      &.pwa-install {
        display: block;
      }
    }
  }
  .modal-body {
    font-size: 1.25rem;
  }
}

// Nav / headers
.page-header {
  $nlGutter: calc(-1 * var(--nl-gutter));
  //margin-left: $nlGutter;
  //margin-right: $nlGutter;
  margin-bottom: 1rem;
  background-color: var(--nl-header-bg);

  font-family: var(--nl-font-avenir);
  font-size: 1.6rem;
  @media (max-width: 768px) {
    font-size: 1.3rem;
  }

  padding: 0;
  .navbar-nav {
    width: 100%;
    .nav-link {
      //font-size: 1.4rem;
      //font-weight: bold;
      img {
        width: 1.8rem;
        @media (max-width: 768px) {
          margin-top: 3px;
        }
      }
    }
    .navbar-text {
      font-weight: 500;
      color: var(--nl-green);
    }
  }
  .right {
    //width: 2rem;
    img {
      height: 40px;
      width: 40px;
      margin-right: 4px;
    }
  }
}

// Home page
.nl-home {

  nav.navbar {

    .navbar-toggler {
      margin-top: -7px;
    }

    .navbar-brand {
      margin-top: 5px;
      margin-right: 3px;
    }

  }

  // Main cards
  .cards {

    @media (min-width: 500px) {
      max-width: 500px;
    }

    margin: 1rem auto 8rem;
    .card {
      background-color: var(--nl-off-white);
      margin: 0 2rem 20px;
      text-align: center;
      color: #6DA67E; /*rgb(109, 166, 126);*/
      font-family: Avenir, Montserrat, Arial, sans-serif;
      a {
        text-decoration: none;
        color: var(--nl-green);
      }
      .card-body {
        .card-title {
          margin-bottom: 0;
          padding: 10px;
          //font-weight: bold;
          font-size: 1.6rem;
        }
      }
      &:hover {
        background-color: #fafafa;
      }
    }
  }

}
#nl-nav {
  .offcanvas-title.h5 img {
    margin-top: -5px;
    margin-left: -6px;
    margin-right: 5px;
  }
}

// Performers page
@mixin performerPad {
  padding: 20px;
    @media (max-width: 768px) {
      padding: 15px;
    }
}
@mixin titlePad {
  padding: 15px 5px 0;
  @media (max-width: 768px) {
    padding: 10px 5px 0 5px;
  }
}


.nl-performers {

  .page-header {
    margin-bottom: 0;
  }

  div.search-field {
    padding: 15px 5px;
    top: 50px;
    background-color: var(--nl-main-bg);
    margin-bottom: -1rem;
  }

  .artist-list {
    margin-top: 1rem;
    div.card {
      /*
      &:hover {
        div.image {
          transition: 0.3s;
          filter: brightness(75%);
        }
      }
      */
    }
  }
}

// Performers list
.artist-list {

  div.card {

    @include performerPad;

    background-color: rgba(255, 255, 255, 0.75);
    margin-bottom: 15px;
    div.image {
      max-width: 500px;
      margin: 0 auto;
      //padding-top: 10px;
      //text-align: center;
      img {
        //width: 95%;
      }
    }
    div.title {

      @include titlePad();
      margin-bottom: 0;
      @media (max-width: 768px) {
        margin-bottom: 0.25rem;
      }
      font-weight: 500;
      > div {
        display: inline-block;
        &.p-fave {
          text-align: right;
        }
      }
    }

  }

}

$nlFont: 24px;
$nlFontSmaller: 1.25rem;
$nlFontSmallest: 1.1rem;

.performer-title, .performer-title > a {
  color: var(--nl-blue);
  font-size: $nlFont;
  > .performer-byline {
    color: var(--nl-performer-byline);
    font-size: 18px;
    margin-top: 5px;
  }
  @media (max-width: 768px) {
    font-size: $nlFontSmaller;
    > .performer-byline {
      font-size: 16px;
    }
  }
}

.venue-title, .venue-title > a {
  color: var(--nl-blue);
  font-size: ($nlFont - 2px);
  @media (max-width: 768px) {
    font-size: $nlFontSmallest;
  }
  //font-weight: 600;
  //text-decoration: none;
}
.venue-number {
  background-color: var(--nl-green);
  color: var(--nl-off-white);
  border-radius: 50%;
  display: inline-flex;
  align-items: end;
  justify-content: center;
  height: 1.8rem;
  width: 1.8rem;
  margin-left: -5px;
  margin-right: 10px;
  line-height: 1.6rem;
  &.empty {
    background-color: transparent;
    display: none;
  }
  &.page {
    align-items: center;
  }
}

// Performer page
.nl-performer-page {
  div.card {

    @include performerPad;

    img {
      max-width: 80%;
      margin: 0 auto;
    }

    background-color: rgba(255, 255, 255, 0.75);
    div.card-title {

      @include titlePad();

      margin-bottom: 0;
      > div {
        display: inline-block;
        &.p-fave {
          text-align: right;
        }
      }
    }
    div.card-body {
      padding: 1rem 5px;
      white-space: break-spaces;
    }
  }
}

// Tabs
ul.nav-tabs {
  background-color: var(--nl-main-bg);
  width: 100%;
  position: sticky;
  top: 3.4rem;
  padding-top: 20px;
  padding-left: 25px;
  z-index: 2;
  border: none;
  border-bottom: 5px solid var(--nl-off-white);
  @media (max-width: 768px) {
    //width: calc(100% + 30px);
    top: 2.8rem;
  }
  .nav-item {
    .nav-link {
      text-decoration: none;
      font-size: 20px;
      &.active {
        background-color: var(--nl-off-white);
        border-color: var(--nl-off-white);
      }
    }
  }
}

div.tab-content {

  margin-top: -1rem;
  padding: 10px 0;
  background-color: var(--nl-off-white);

  > div.tab-pane {
    position: relative;
    > .schedule-list, > .artist-list {
      padding: 0;
      background-color: var(--nl-off-white);
    }
    > .artist-list {
      padding: 20px 10px;
    }
  }

  div.schedule-list {

    div.row  {
      margin: 10px;
      padding: 10px;
      background-color: var(--nl-main-bg);
    }
  }

}

// Schedule page
.nl-schedules {

  position: relative;
  .page-header {
    margin-bottom: 0;
  }

  .schedule-list {
    padding-top: 10px !important;
  }

  @media (max-width: 768px) {
    h4 {
      font-size: 1.2rem;
    }
    h5 {
      font-size: 1.1rem;
    }
  }

}

// Venue list
div.nl-venues {

  div.card {
    div.card-header {
      div.venue-title {
        margin: 10px 0;
      }
    }
    div.card-body {
      white-space: break-spaces;
    }
    div.card-footer {
      .col {
        display: inline-block;
      }
    }
  }

}

// Venue page
div.nl-venue-page, #modalMap {
  div.card {
    div.card-body {
      //padding: 22px 18px 18px;
    }
    div.card-header {
      div.card-title {
        margin: 10px 0;
        @media (max-width: 768px) {
          font-size: $nlFontSmallest;
        }
      }
    }
  }
  div.card-footer {
    div.address {
      span.mapMarker {
        span.svgMapMarker {
          .st0 {
            fill: #FE7569;
          }
        }
      }
    }
  }
}

// Both venue pages
div.nl-venues, div.nl-venue-page, div.nl-performers, div.nl-performer-page, #modalMap {
  div.card {
    @media (max-width: 768px) {
      border-left: none;
      border-right: none;
      border-radius: 0;
    }
  }
  div.card-footer {
    font-weight: 500;
  }
}

// modalMap
#modalMap {
  div.card-footer {
    font-weight: 500;
    margin: 1rem -1rem -1rem;
    padding: 0.5rem 2rem;
    background-color: rgba(0,0,0,.03);
    border-top: 1px solid rgba(0,0,0,.175);
    div.address {
      span.mapMarker {
        span.svgMapMarker {
          .st0 {
            fill: #FE7569;
          }
        }
      }
    }
  }
}

// Favourites
.nl-favourites {

  .page-header {
    margin-bottom: 0;
  }

  .schedule-list {
    h6 {
      padding: 1.1rem;
    }
  }

  .artist-list {
    padding: 0 10px;
    h6 {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  .no-break {
    white-space: nowrap;
  }

}

// Schedule component
div.schedule-list {

  div.performer-title {
    // was h4
    font-size: 22px;
    margin-left: 25px;
    margin-bottom: 5px;
  }
  div.extra {
    margin-left: 25px;
    white-space: break-spaces;
  }
  div.workshop {
    margin-left: 25px;
    font-size: 15px;
    font-style: italic;
    color: var(--nl-performer-byline);
  }
  div.venue-title {
    $fs: 20px;
    // was h5
    font-size: $fs;
    > a {
      font-size: $fs;
    }
    //font-weight: 500;
    margin-top: 10px;
    svg {
      margin-top: -2px;
      margin-left: -5px;
      margin-right: 5px;
      width: auto;
      height: 25px;
    }
  }
  div.time {
    font-weight: 500;
    margin-top: 5px;
    > svg {
      margin-top: -5px;
      margin-left: -5px;
      margin-right: 5px;
      width: auto;
      height: 25px;
    }
  }

  @media (max-width: 768px) {
    h4 {
      font-size: 1.3rem;
    }
    h5 {
      font-size: 1.1rem;
    }
  }

  .date-header {
    display: block;
    margin-top: 1rem;
    padding: 0.5rem 0.8rem;
    background-color: #6DA67E;
    color: white;
    font-weight: 600;
    font-size: 1.2rem;
  }

  div.row {
    margin: 0;
    padding: 10px ;
    border-bottom: 2px solid var(--nl-off-white);
    > div {
      display: inline-block;
      &.details {
        padding-left: 5px;
      }
      &.fave {
        text-align: right;
        padding: 0;
      }
    }
  }

}

// Breakout box component
.breakout-box {
  border: 5px solid var(--nl-green);
  margin-top: 1rem;
  padding: 2rem;
  .header {
    position: relative;
    .svg {
      display: inline;
      float: left;
      svg {
        margin-top: -2px;
        height: 30px;
        width: auto;
        fill: var(--nl-green);
      }
    }
    .title {
      display: inline;
      h3 {
        font-size: 1.6rem;
        font-weight: bold;
        margin-left: 2.8rem;
        margin-top: 5px;
      }
    }
  }
  .divider {
    border-top: 3px solid black;
    margin-top: 28px;
  }
  .contents {
    margin-top: 3rem;
    h4 {
      font-size: 18px;
      margin-bottom: 8px;
      color: var(--nl-green);
    }
    h5 {
      font-size: 16px;
      margin-bottom: 8px;
      color: var(--nl-text);
    }
    .action-button {
      text-align: center;
      margin: 1.5rem 0;
      > a {
        white-space: nowrap;
        background-color: var(--nl-green);
        color: white;
        align-items: center;
        border-radius: 40px;
        padding: 8px 25px;
        text-decoration: none;
      }
    }
  }

}

.nl-faq {
  .breakout-box {
    .header {
      .svg {
        svg {
          width: 30px;
          height: auto;
        }
      }
    }
    .contents {
      h4 {
        font-size: 1.3rem;
        color: goldenrod;
        font-weight: 600;
      }
    }
  }
}

.fav-button {
  color: var(--nl-green);
  font-size: 1.6rem;
  border: none;
  background-color: transparent;
  text-decoration: none;
}

.modal-body {
  > p {
    margin: 10px 0 0;
  }
}

div.modal-venue {
  .modal-dialog {
    @media (min-width: 576px) and (max-width: 991px) {
      --bs-modal-width: 80vw;
    }
  }
}

div.static-map {
  position: relative;
  div.map-controls {
    position: absolute;
    bottom: 10px;
    right: 10px;
    > button {
      min-width: 40px;
      margin-left: 10px;
      //padding: 2px 5px;
      font-size: 1.2rem;
      border-radius: 10px;
      text-align: center;
    }
  }
  div.img-map {
    overflow: scroll;
    max-height: 65vh;
    img {
      height: auto;
      transition: opacity 350ms ease;
      &.dim {
        opacity: 0.1;
      }
    }
  }
}

div.address, .venue-title {
  span.svgMapMarker {
    svg {
      width: 25px;
      height: auto;
      margin-left: -5px;
      margin-right: 6px;
      margin-top: -4px;
      .st0 {
        fill: var(--nl-green);
      }
    }
  }
}

span.svgClock {
  svg {
    width: 25px;
    height: auto;
    margin-left: -5px;
    margin-right: 6px;
    margin-top: -4px;
    .st0 {
      stroke: var(--nl-green);
      stroke-width: 1.3px;
    }
  }
}

p.location {
  margin-top: 10px;
  margin-bottom: 0;
  font-style: italic;
}
div.address {
  margin-top: 5px;
  margin-bottom: 5px;
  a {
    //text-decoration: underline;
    //color: var(--nl-text);
  }
  p.gmap {
    margin-top: 10px;
    margin-bottom: 0;
    font-style: italic;
    text-decoration: none;
  }
}
div.card-title {
  color: var(--nl-blue);
}

.modal-title {
  font-size: $nlFont;
  color: var(--nl-blue);
}
.modal-body {
  a {
    color: var(--nl-blue);
  }
}
@media (max-width: 768px) {
  .modal-title {
    font-size: $nlFontSmallest;
  }
  .modal-body, .modal-header, .modal-footer {
    padding: 10px;
  }
}
